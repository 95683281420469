/* styles/history-theme.css */

.history-theme-card-body {
    padding: 20px;
    text-align: center;
  }
  
  .history-theme-card-content {
    text-align: left; /* Alinha o conteúdo ao início */
  }
  
  .history-theme-card-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #007bff;
  }
  
  .history-theme-card-text {
    margin-bottom: 15px;
  }
  
  .history-theme-badge {
    display: inline-block;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 50px;
    font-weight: 600;
  }
  
  .history-theme-carousel {
    margin-bottom: 15px;
    height: 200px; /* Altura fixa para o carrossel */
    overflow: hidden; /* Garante que o conteúdo não vaze para fora do contêiner */
  }
  
  .history-theme-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .history-theme-img:hover {
    transform: scale(1.05);
  }
  
  .history-theme-btn {
    width: 100%;
    font-weight: 600;
  }
  