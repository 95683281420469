/* styles/add-theme.css */

.add-theme-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f9fc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-theme-title {
    color: #343a40;
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 20px; /* Espaço entre o título e o formulário */
}

.add-theme-form {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Aumentado o espaçamento entre os grupos de campos */
}

.add-theme-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.add-theme-form-label {
    font-weight: 600;
    color: #495057;
    margin-bottom: 5px; /* Espaço entre o label e o input */
}

.add-theme-form-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #fff;
    transition: border-color 0.2s;
}

.add-theme-form-control:focus {
    border-color: #007bff;
    outline: none;
}

.add-theme-form-check {
    display: flex;
    align-items: center;
}

.add-theme-form-check-label {
    font-weight: 500;
    color: #495057;
    margin-left: 8px;
}

.add-theme-custom-fields {
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 15px;
    background-color: #ffffff;
}

.add-theme-custom-fields-legend {
    font-size: 1.2rem;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 10px;
}

.add-theme-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-theme-btn-danger {
    padding: 10px;
    font-weight: 600;
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.add-theme-btn-primary {
    padding: 10px 20px;
    font-weight: 600;
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.add-theme-btn-secondary {
    padding: 10px 20px;
    font-weight: 600;
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.add-theme-btn-primary i,
.add-theme-btn-secondary i,
.add-theme-btn-danger i {
    margin-right: 5px;
}
