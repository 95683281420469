/* styles/edit-theme.css */

.edit-theme-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f9fc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-theme-title {
    color: #343a40;
    font-weight: 700;
    font-size: 1.8rem;
}

.edit-theme-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.edit-theme-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.edit-theme-form-label {
    font-weight: 600;
    color: #495057;
    margin-bottom: 5px;
}

.edit-theme-form-control {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #fff;
    transition: border-color 0.2s;
}

.edit-theme-form-control:focus {
    border-color: #007bff;
    outline: none;
}

.edit-theme-form-check {
    display: flex;
    align-items: center;
}

.edit-theme-form-check-label {
    font-weight: 500;
    color: #495057;
    margin-left: 8px;
}

.edit-theme-custom-fields {
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 15px;
    background-color: #ffffff;
}

.edit-theme-custom-fields-legend {
    font-size: 1.2rem;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 10px;
}

.edit-theme-input-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-theme-btn-danger {
    padding: 10px;
    font-weight: 600;
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.edit-theme-btn-primary,
.edit-theme-btn-secondary,
.edit-theme-btn-info {
    padding: 10px 20px;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.edit-theme-btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.edit-theme-btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
}

.edit-theme-btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.edit-theme-btn-primary i,
.edit-theme-btn-secondary i,
.edit-theme-btn-danger i,
.edit-theme-btn-info i {
    margin-right: 5px;
}

.edit-theme-snapshots-fieldset {
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 15px;
    background-color: #ffffff;
}

.edit-theme-snapshots-legend {
    font-size: 1.2rem;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 10px;
}

.edit-theme-snapshots {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.edit-theme-snapshot {
    flex: 0 0 calc(33.333% - 15px);
}

.edit-theme-snapshot img {
    width: 100%;
    border-radius: 8px;
}

.edit-theme-carousel-img {
    height: auto; /* A altura é automática para manter a proporção da imagem */
    max-height: 500px; /* Altura máxima para controlar a altura das imagens */
    object-fit: contain; /* Garante que a imagem seja redimensionada corretamente dentro do container */
  }
  
  .edit-theme-carousel {
    max-height: 500px; /* Limita a altura do carrossel */
    overflow: hidden; /* Evita que o conteúdo do carrossel exceda os limites */
  }
  
  .edit-theme-snapshots-fieldset {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
  }
  
  .edit-theme-snapshots-legend {
    font-size: 1.2rem;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 10px;
  }
