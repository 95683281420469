/* login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa; /* Cor de fundo clara */
}

.login-card {
  max-width: 900px;
  margin: 20px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Sombra suave e profunda */
  background-color: white !important;
}

.card-body-login {
  background-color: white !important;
}

.login-card img {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

/* login.css */

.theme-manager-title {
  color: #6c757d; /* Cor cinza suave */
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2.5rem !important;
}

.login-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para o card */
}

@media (max-width: 991.98px) {
  .login-card img {
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.card-body {
  padding: 3rem 2.5rem;
}

.form-outline .form-control {
  background-color: #fff;
}

.form-outline .form-label {
  color: #495057;
}

.btn-block {
  width: 100%;
}
