/* styles/add-theme-version.css */

.add-theme-version-container {
    padding: 20px;
    background-color: #f7f9fc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-theme-version-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .add-theme-version-form-group {
    margin-bottom: 15px;
  }
  
  .add-theme-version-form-label {
    font-weight: 600;
    color: #495057;
    margin-bottom: 5px;
  }
  
  .add-theme-version-form-control {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
  
  .add-theme-version-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .add-theme-version-btn {
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .add-theme-version-btn-cancel {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
  }
  
  .add-theme-version-btn i {
    margin-right: 5px;
  }
  