/* styles/footer.css */

.footer {
    font-size: 0.9rem; /* Tamanho da fonte levemente menor para um rodapé discreto */
    background-color: #343a40; /* Fundo escuro padrão para harmonia com o header */
    color: #ffffff; /* Texto branco para contraste */
  }
  
  .footer p {
    margin-bottom: 0; /* Remover a margem inferior padrão dos parágrafos */
  }
  
  .footer a {
    color: #ffc107; /* Cor de destaque para links, se houver */
  }
  
  .footer a:hover {
    color: #e0a800; /* Cor do hover para os links */
  }
  