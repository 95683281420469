/* styles/header.css */

/* Tamanho padrão para o ícone */
.header-icon {
    font-size: 1.5rem; /* Tamanho para dispositivos móveis */
}

/* Tamanho maior para telas maiores */
@media (min-width: 768px) {
    .header-icon {
        font-size: 2rem; /* Aumenta o tamanho para telas médias */
    }
}

@media (min-width: 1024px) {
    .header-icon {
        font-size: 2.5rem; /* Aumenta ainda mais para telas grandes */
    }
}

/* styles/header.css */

.search-input {
    max-width: 100%;  /* Certifique-se de que o input não ultrapasse o espaço disponível */
    min-width: 150px; /* Define um tamanho mínimo para garantir que sempre seja visível */
}

@media (max-width: 768px) {
    .search-box {
        max-width: 60% !important; /* Para telas pequenas, a caixa de pesquisa ocupa 60% da largura disponível */
    }
    .search-input {
        width: 100%; /* Para telas pequenas, o input ocupa 100% da largura disponível */
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
