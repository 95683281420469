/* styles/themes.css */

.themes-container {
    margin-top: 20px;
}

.themes-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.card.themes-card {
    border: none;
    background-color: #faf3e0; /* Cor de fundo palha claro */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    overflow: hidden; /* Garantir que o dropdown não saia do card */
}

.card.themes-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-header.themes-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f9efd1; /* Cor palha para o cabeçalho */
    border-bottom: 1px solid #e9ecef;
    font-weight: 600;
    font-size: 1.25rem;
    color: #495057; /* Cor cinza escuro para o título, melhorando o contraste */
}

.card-body.themes-card-body {
    padding: 20px;
    text-align: center;
    background-color: #faf3e0; /* Cor de fundo palha claro */
}

.img-fluid.themes-img-fluid {
    border-radius: 5px;
    transition: transform 0.3s;
    margin-bottom: 20px;
    max-height: 200px; /* Limitar a altura máxima da imagem */
    overflow: hidden; /* Ocultar conteúdo que exceder a altura */
    object-fit: cover; /* Ajusta a imagem para cobrir a altura máxima mantendo a proporção */
}

.img-fluid.themes-img-fluid:hover {
    transform: scale(1.05);
}

.themes-badge-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.themes-badge-custom {
    width: 100%;
    font-size: 0.85rem;
    padding: 10px 0;
    border-radius: 50px; /* Arredondando os badges */
    font-weight: 600;
}

.themes-btn {
    margin: 5px 0;
    width: 100%;
}

.dropdown-menu.themes-dropdown-menu {
    width: 100%;
    text-align: left;
    border-radius: 0.25rem;
}

.dropdown-item.themes-dropdown-item {
    display: flex;
    align-items: center;
}

.dropdown-item.themes-dropdown-item i {
    margin-right: 8px;
    font-size: 1rem;
}

.btn-success.themes-btn-success {
    width: 100%;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success.themes-btn-success:hover {
    background-color: #218838;
}

.btn-primary.themes-btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
}

.btn-primary.themes-btn-primary i {
    font-size: 1.25rem;
    margin-right: 8px;
}

.btn-add.themes-btn-add {
    width: auto !important;
}
